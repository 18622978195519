import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import { BQLink } from '../../../atoms/bqlink';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import Loader from '../../../atoms/icons/loader';
import Lottie from 'lottie-react';
import NewsletterIcon from '../../../molecules/newsletter/lottie-message-icon.json';
import Icon from '@mdi/react';
import { makeApiCall } from '../../../../helpers/utils';
import { mdiAlertOutline, mdiChevronRight, mdiAt, mdiCheck, mdiCheckCircle } from '@mdi/js';

import './footer-news-letter.m.css';

const FooterNewsLetter = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("You're in our Mailing list");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('Please enter a valid Email ID.');
  const userInfo = useSelector((state) => get(state, ['userData', 'userProfile'], null));
  const espConfig = useSelector((state) => get(state, ['qt', 'config', 'integrations', 'esp_config'], []));
  const baseUrl = espConfig && espConfig.base_url;
  const apiKey = espConfig && espConfig.api_key;
  const listName = 'TalkingPoints';
  const isName = 'Talking';

  useEffect(() => {
    if (!!userInfo?.email && espConfig) {
      checkExistingSubscription(userInfo.email, 'user');
    }
  }, [userInfo]);

  const checkExistingSubscription = async (email, state) => {
    let temp = false;
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json, application/xml',
      mmApiKey: apiKey,
    };
    await makeApiCall(
      `${baseUrl}/api/v1/getContactDetails?email=${email}`,
      'GET',
      {},
      headers,
    ).then((res) => {
      if (res?.message && res?.message === 'Email does not exist') {
        return false;
      } else if (res?.data?.[isName] && res?.data?.[isName] === 'true') {
        setSuccess(true);
        setSuccessMessage('You are already subscribed');
        if (state !== 'user') {
          setSuccessMessage('You are already subscribed to this newsletter');
          setTimeout(() => {
            setEmail('');
            setSuccess(false);
          }, 3000);
          temp = true;
        }
      } else {
        return false;
      }
    });
    return temp;
  };

  const validateEmail = (email) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      setError(true);
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    const existing = await checkExistingSubscription(email, 'input');
    if (validateEmail(email) && !existing) {
      setIsLoading(true);
      setError(false);
      try {
        const endpoint = `${baseUrl}/api/v1/addToList`;
        const payload = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json, application/xml',
            mmApiKey: apiKey,
          },
          body: `{ "email": "${email}", "listName": "${listName}", "data": { "${isName}": "true" } }`,
        };
        const response = await fetch(endpoint, payload);
        if (response?.status === 200) {
          setIsLoading(false);
          setSuccess(true);
          setSuccessMessage('You have been added to our mailing list');
          if (!!userInfo?.email ? userInfo?.email !== email : true) {
            setTimeout(() => {
              setEmail('');
              setSuccess(false);
            }, 2000);
          }
        }
        if (response?.status === 400) {
          setError(true);
          setMessage('Please enter a valid Email ID.');
        }
      } catch (e) {
        setError(true);
        setMessage('Unable to process. Please try again.');
      }
    }
    setIsLoading(false);
  };
  return (
    <div styleName="card-wrapper">
      <div styleName="card">
        <div styleName="top-section">
          <div styleName="icon">
            <Lottie animationData={NewsletterIcon} loop={true} autoplay={true} />
          </div>
          <div styleName="icon-text">
            <span>The Talking Point</span>
          </div>
        </div>
        <div styleName="form-wrap">
          {error && <span styleName="arrow error-title">{message}</span>}
          <div styleName="signup-form">
            <div styleName="email-section">
              <Icon styleName="email-icon" path={mdiAt} size={'20px'} color="#C7CDD1" />
              <input
                styleName={`email-input ${error ? 'invalid-error' : ''}`}
                type="email"
                placeholder="Enter Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading || success}
              />
              {error && (
                <Icon styleName="error-icon" path={mdiAlertOutline} size={'20px'} color="#d01e51" />
              )}
              {success && (
                <Icon styleName="error-icon" path={mdiCheckCircle} size="20px" color="#14C588" />
              )}
            </div>
            {success ? (
              <button styleName="signup-success" type="button" disabled={isLoading}>
                <Icon path={mdiCheck} size={'26px'} color="#fff" />
              </button>
            ) : (
              <button
                styleName="signup-button"
                type="button"
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                {isLoading ? <Loader color="#fff" /> : 'SIGN UP'}
              </button>
            )}
          </div>
        </div>
        {success && (
          <div styleName="success-msg">{successMessage}</div>
        )}
        <BQLink href="/my-account/newsletter?src=nl_ftr" target="_blank" styleName="link-btn">
          <div styleName="newsletters">
            <span>View All Newsletters</span>
            <Icon path={mdiChevronRight} size="17px" />
          </div>
        </BQLink>
      </div>
    </div>
  );
};
FooterNewsLetter.propTypes = {
  espConfig: object,
};

const mapStateToProps = (state) => ({
  espConfig: get(state, ['qt', 'config', 'integrations', 'esp_config'], null),
});
export default connect(mapStateToProps)(FooterNewsLetter);
